// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Skins
//
// We recommend that all the variables that are staying at the core of your product's styleguide (colors, typography) to be separated in a custom skin
// you can start by using one of the pre-built ones or, simply, create a new one
// learn more about how to create your skin by reading the theme's documentation.
//
// Below you can find 3 pre-defined skins. You can uncomment any one of them and compile Sass using the build tools

// @import "../skins/green/light";
// @import "../skins/green/dark";

// Fonts
//
// uncomment and replace the $font and $font-family-custom-sans-serif value with any URL and font family name from Google Fonts
//

$font: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900" !default;
$font-family-custom-sans-serif: 'Source Sans Pro', sans-serif !default;
$font-family-base: $font-family-custom-sans-serif !default;

// Custom variables
//
// Need additional variables? Simply create it here and make sure you follow the naming formula explained at the top of this file.
