//
// Fonts
//


// Import fonts from Google
//
// Using the $font variable you can import any font family from Google Fonts.
// go to https://fonts.google.com copy and paste the URL of the selected font from the @import tab
// (e.g: https://fonts.googleapis.com/css?family=Nunito) and peste it in the _variables.scss from the custom folder

@import url(#{$font});

// Create font from local font
//
// if you want to create a custom font family, first comment the @import from line 12.
// copy and paste you font files in assets/fonts (you might need to create the folder)
//
// use this file to create the @font-face
// open custom/_variables.scss uncomment and replace the $font-family-custom-sans-serif value with the CUSTOM_FONT face you created

// Font face example

/*@font-face {
     font-family: "Source Sans Pro";
     src: url("fonts/SourceSansPro-Black.ttf") format("Black");
     src: url("fonts/SourceSansPro-BlackItalic.ttf") format("BlackItalic");
     src: url("fonts/SourceSansPro-Bold.ttf") format("Bold");
     src: url("fonts/SourceSansPro-BoldItalic.ttf") format("BoldItalic");
     src: url("fonts/SourceSansPro-ExtraLight.ttf") format("ExtraLight");
     src: url("fonts/SourceSansPro-ExtraLightItalic.ttf") format("ExtraLightItalic");
     src: url("fonts/SourceSansPro-Italic.ttf") format("Italic");
     src: url("fonts/SourceSansPro-Light.ttf") format("Light");
     src: url("fonts/SourceSansPro-LightItalic.ttf") format("LightItalic");
     src: url("fonts/SourceSansPro-Light.ttf") format("Light");
     src: url("fonts/SourceSansPro-Regular.ttf") format("Regular");
     src: url("fonts/SourceSansPro-SemiBold.ttf") format("SemiBold");
     src: url("fonts/SourceSansPro-SemiBoldItalic.ttf") format("SemiBoldItalic");
}
*/
