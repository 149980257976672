// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.




// HOMEPAGE //////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////


.top-banner-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #24243A;
}

.top-banner-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #24243A !important;
}

.section-top-banner {
    background: linear-gradient(180deg, #E4EFFA 0%, rgba(228, 239, 250, 0) 100%);
    padding-top: 140px !important;
    padding-bottom: 100px !important;
}

@media (max-width: 990px) {
    .section-top-banner {
        padding-top: 70px !important;
    }
}

.top-banner-img {
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15)) drop-shadow(0px 24px 64px rgba(22, 27, 45, 0.05));
}

.navbar-nav .nav-link {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    top: 38.55%;
    bottom: 38.55%;
    text-transform: none;
    letter-spacing: 0;
    transition: all 0.15s linear;
}

.wpml-ls-legacy-dropdown a {
    color: #FFFFFF !important;
}

.pc-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    text-align: left;
    color: #24243A;
}

.pc-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
    color: #24243A;
}

.avatar {
    position: relative;
    color: #FFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    height: 120px;
    width: 120px;
    border-radius: 0.375rem;
    img{
        height: 100%;
        object-fit: cover;
    }
}

.circular-image-container {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background: linear-gradient(180deg, #E4EFFA 0%, rgba(228, 239, 250, 0) 100%);
}

.image-in-circular-container {
    width: 100%;
    top: 110px;
    position: relative;
    left: 85px;
}

.image-in-circular-container-special {
    width: 100%;
    position: relative;
    padding: 50px;
}

.ph-container-top {
    margin-top: 100px;
}
.ph-container-bottom {
    margin-bottom: 100px;
}

.banner-container {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
}

.mid-screen-banner-sec-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 120%;
    color: #24243A;
}

.bg-footer-custom {
    background-color: #24243A !important;
}

.sticky-menu {
    background: transparent;
    height: 60px;
    z-index: 170 !important;
    margin: 0 auto;
    width: 100% !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
}


// REMOVING STYLE.CSS ////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////

.btn-primary {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8;
}
.btn {
    display: inline-block;
    font-weight: normal;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f7f7f9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

a {
    color: #0275d8;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

body {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #292b2c;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
.card-title {
    margin-bottom: 0.75rem;
}

// LOGO SECTION //////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////

